<template>
  <div  class="container my-3">
    <div class="row my-2">
      <div class="col-sm-4">
      </div>
      <div class="col-sm-4">
        <b-nav align="center">
          <h4 class="my-1">
            PianoCharts 
          </h4>
        </b-nav>
      </div>
      <div class="col-sm-4">

      </div>
    
    </div>
    <div class="row">
      <div class="col-sm-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">
              Overview for {{ year }}
            </h5>
            <ul  style="list-style-type:none;padding-left:0">
              <li> ${{ stats.income }} Income for the year </li>
              <li> {{ stats.lessons }} Total Lessons</li>
              <li> {{ stats.all_students }} Total Students </li>
              <li> {{ stats.new_students }} New students </li>
            </ul>
            
          </div>
        </div>
      </div>
      <div class="col-sm-6">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
      </div>
      <div class="col-sm-6">
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Home',

    data: ()=> {
    return {
      year: new Date().getUTCFullYear(),
      stats: {
        income: 0,
        lessons: 0,
        all_students: 0,
        new_students: 0
      }
    }
  },
}
</script>